module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DosugRu.Net","short_name":"DosugRu.Net","icons":[{"src":"/favicon.ico","type":"image/x-icon","sizes":"32x32"},{"src":"/icon-192.png","type":"image/png","sizes":"192x192"},{"src":"/icon-512.png","type":"image/png","sizes":"512x512"},{"src":"/icon-192-maskable.png","type":"image/png","sizes":"192x192","purpose":"maskable"},{"src":"/icon-512-maskable.png","type":"image/png","sizes":"512x512","purpose":"maskable"}],"theme_color":"#ffffff","background_color":"#ffffff","display":"standalone","orientation":"portrait","start_url":"/","description":"Web App for «DosugRu.Net» web site","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
